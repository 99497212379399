<template>
    <div class="wrap">
        <div class="space_pc">
            <section class="main space4_4">
                <div class="main_box">
                    <h3>
                        설정하신 큐레이션 조건의<br>
                        채널을 신규로 생성하시겠습니까?
                    </h3>
                    <div class="connect-wrap">
                        <div class="links"><img src="/media/img/img_album_icon.png"></div>
                        <div class="smiley">
                            <div><img src="/media/img/icon_music_cover.png" alt=""></div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="loading_ing2">
                <div @click="linkStore5()"><button>네, 새로운 채널을 생성할래요.</button></div>
                <div @click="linkNext()"><button>아니오, 기존 채널을 이용할래요.</button></div>
            </div>
        </div>
        <!-- 모바일 -->
        <div class="space_mo h_100">
            <section class="main space4_4">
                <div class="main_box">
                    <h3>
                        설정하신 큐레이션 조건의<br>
                        채널을 신규로 생성하시겠습니까?
                    </h3>
                    <div class="connect-wrap">
                        <div class="smiley"><img src="/media/img/icon_music_cover.png" alt=""></div>
                        <div class="links">
                            <img src="/media/img/img_album_icon.png">
                        </div>
                    </div>
                </div>

            </section>
            <div class="form_bottom2">
                <div @click="linkStore5()"><button>네, 새로운 채널을 생성할래요.</button></div>
                <div @click="linkNext()"><button>아니오, 기존 채널에 참여할래요.</button></div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import router from "@/router";

export default defineComponent({
  name: "FinishedCuration",
  methods: {
    linkStore5 () {
      router.push({ name: "SpaceInsertStore5" });
    },
    linkNext () {
      const randomNum = Math.floor(Math.random() * 10 + 1);
      randomNum > 5 ? router.push({ name: "SpaceInsertSearchChannel" }) : router.push({ name: "SpaceInsertFindChannel" });
    }
  }
});
</script>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
